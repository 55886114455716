import * as React from "react";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { ApplicationShell } from "../../molecules/ApplicationShell/ApplicationShell";
import { WithAuth } from "../../molecules/Auth/WithAuth";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { EditApplicationForm } from "./EditApplicationForm";

interface IProps {
    readonly applicationId: string;
}

export const EditApplicationPage: React.FC<React.PropsWithChildren<IProps>> = ({ applicationId }) => {
    const [t] = useTranslation();
    const description = t("newApplicationPage.description");
    const title = t("newApplicationPage.title");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={AppRoute.Applications} showNavigation={false}>
            <WithAuth>
                <ApplicationShell>
                    <EditApplicationForm applicationId={applicationId} />
                </ApplicationShell>
            </WithAuth>
        </ScrollablePageTemplate>
    );
};
