import { ApplicationStatus } from "@ds160/library";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

export interface IApplication {
    readonly _id: string;
    readonly projectName: string;
    readonly status: ApplicationStatus;
    readonly created: string;
    readonly revision: number;
    readonly userId: string;
}

interface IResponse {
    readonly application: IApplication;
}

const getApplication = async (config: AxiosRequestConfig, applicationId: string) => {
    const uri = `${Constants.ApiRoot}/application/${applicationId}`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data.application;
};

export const useApplication = (applicationId: string) => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Applications, applicationId], () => getApplication(config, applicationId));
};
