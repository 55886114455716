import * as React from "react";
import { useApplication } from "../../../hooks/api/application/useApplication";
import { AppRoute } from "../../../services/Constants/AppRoute";
import { BreadCrumb, IPage } from "../../atoms/BreadCrumb/BreadCrumb";
import { Loading } from "../../atoms/Loading/Loading";
import { ConsulateSelection } from "./ConsulateSelection";

interface IProps {
    readonly applicationId: string;
}

export const EditApplicationForm: React.FC<React.PropsWithChildren<IProps>> = ({ applicationId }) => {
    const application = useApplication(applicationId);
    if (application.isLoading || application.data === undefined) {
        return <Loading />;
    }
    const { projectName } = application.data;
    const pages: Array<IPage> = [{ name: "Applications", href: AppRoute.Applications }, { name: projectName }];
    return (
        <form>
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <BreadCrumb pages={pages} />
            </div>

            <div className="bg-white px-4 py-5 sm:px-6">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base/7 font-semibold text-gray-900">Location</h2>
                        <p className="mt-1 text-sm/6 text-gray-600">
                            This is the US embassy or consulate where you plan to apply for your visa.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-4">
                            <ConsulateSelection />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm/6 font-semibold text-gray-900">
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>
        </form>
    );
};
